import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import { GatsbyImage, getImage, getImageData } from "gatsby-plugin-image"
import FullWidthImage from "../components/FullWidthImage";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

// eslint-disable-next-line
export const ImpressumPageTemplate = ({ 
  title,
  featuredimage,
  content, 
  contentComponent, 
  intro,
}) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
              {/* <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                {title}
              </h2> */}
              <FullWidthImage img={ getImage(featuredimage) } height={ "75vh" } imgPosition="center"/>
              {/* <GatsbyImage image={ getImage(featuredimage) } /> */}
              <PageContent className="content" content={content} />
              <div className="columns is-multiline" >
                {intro != null && intro.images?  intro.images.map((object) => (
                  <div className="column is-4">
                    {/* <FullWidthImage img={ getImage(object.image) }  /> */}
                    <GatsbyImage image={ getImage(object.image) } alt={ object.alttext }/>
                  </div>
                )): ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

ImpressumPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  featuredimage: PropTypes.object,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  intro: PropTypes.shape({
    images: PropTypes.array,
  }),
};

const ImpressumPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ImpressumPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        featuredimage={post.frontmatter.featuredimage}
        content={post.html}
        intro={post.frontmatter.intro}
      />
    </Layout>
  );
};

ImpressumPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ImpressumPage;

export const impressumPageQuery = graphql`
  query ImpressumPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
